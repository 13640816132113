import { Restriction } from '@apis/restrictions';
import { Modal, ModalRef } from '@oriola-corporation/ui-components';
import { isServer } from '@packages/gatsby-utils';
import { navigate } from 'gatsby';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigationState } from '../../hooks/useNavigationState';
import { DiklofenakModalContent } from './DiklofenakModalContent';

const modalContents = {
  diklofenak: DiklofenakModalContent,
};

const modalTitles = {
  diklofenak: 'diclaimer.diklofenak.title',
} as const;

const ProductDisclaimerContext = createContext<{
  hasAcceptedDisclaimer: boolean;
  acceptDisclaimer: () => void;
  openDisclaimerModal: (onAcceptedClick: () => void) => void;
  substance: 'diklofenak';
  isOnProductPage: boolean;
  navigateToProduct?: () => void;
} | null>(null);

export const ProductDisclaimerProvider = ({
  children,
  substance,
  isOnProductPage = false,
}: {
  children: React.ReactNode;
  substance: 'diklofenak';
  isOnProductPage?: boolean;
}) => {
  const localStorageKey = `${substance}-disclaimer-accepted`;
  const [hasAcceptedDisclaimer, setHasAcceptedDisclaimer] = useState(() => {
    if (isServer()) return false;
    return JSON.parse(window.localStorage.getItem(localStorageKey) || 'false');
  });
  const [openDisclaimerModalAtStart] = useNavigationState('openDisclaimerModalAtStart', false);

  const handleAcceptDisclaimerClick = useRef(() => {});
  const { t } = useTranslation('productPage');
  const ref = useRef<ModalRef>(null);

  const acceptDisclaimer = () => {
    ref.current?.closeModal();
    handleAcceptDisclaimerClick.current();
    setHasAcceptedDisclaimer(true);
    window.localStorage.setItem(localStorageKey, 'true');
  };

  const openDisclaimerModal = (onAcceptedClick: () => void) => {
    ref.current?.openModal();
    handleAcceptDisclaimerClick.current = onAcceptedClick;
  };

  const ModalContent = modalContents[substance];

  useEffect(() => {
    if (openDisclaimerModalAtStart && !hasAcceptedDisclaimer) {
      ref.current?.openModal();
    }
  }, [hasAcceptedDisclaimer, openDisclaimerModalAtStart]);

  return (
    <ProductDisclaimerContext.Provider
      value={{
        hasAcceptedDisclaimer,
        acceptDisclaimer,
        openDisclaimerModal,
        substance,
        isOnProductPage,
      }}
    >
      {children}
      <Modal
        ref={ref}
        id="product-disclaimer-modal"
        title={t(modalTitles[substance])}
        actions={[
          {
            label: 'Okej, jag förstår',
            onClick: () => {
              acceptDisclaimer();
            },
          },
          {
            label: 'Avbryt köpet',
            onClick: () => ref.current?.closeModal(),
            variant: 'tertiary',
            fontColor: 'green',
          },
        ]}
      >
        <ModalContent />
      </Modal>
    </ProductDisclaimerContext.Provider>
  );
};

export const useProductDisclaimer = <T extends { url: string; restriction?: Restriction | null }>({
  product,
  shouldOpenModal = false,
}: {
  product: T;
  shouldOpenModal?: boolean;
}) => {
  const context = React.useContext(ProductDisclaimerContext);
  if (!context) return null;

  if (product.restriction?.substance?.toLowerCase() === context.substance.toLowerCase())
    return {
      ...context,
      showDisclaimer: (callback: () => void) => {
        if (context.hasAcceptedDisclaimer) return callback();
        if (shouldOpenModal)
          return context.openDisclaimerModal(() => {
            callback();
          });

        window.scrollTo(0, 0);
        return (
          product.url && navigate(product.url, { state: { openDisclaimerModalAtStart: true } })
        );
      },
    };

  return null;
};
