import { RootThemeProvider, ThemeSwitcher } from '@oriola-corporation/ui-components';
import { AuthenticatedApiProvider } from '@packages/api';
import { AuthProvider } from '@packages/auth';
import { getConfigurationVariable } from '@packages/config';
import { DeviceProvider } from '@packages/device';
import { GrowthBookProvider } from '@packages/growthbook';
import { LazyLoadingProvider } from '@packages/lazy-loading';
import { buildMemberQueryKey } from '@packages/member';
import { ProductAvailabilityProvider } from '@packages/product-availability';
import { RatingStars } from '@packages/ui';
import { WebsiteMessagesProvider } from '@packages/website-messages';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import {
  DatadogConfig,
  EnvironmentProvider,
  FeatureFlagsConfig,
  TestFreaksConfig,
} from '../components/environment';
import { ProductRestrictionProvider } from '../components/product-restrictions';
import { SkipLinkProvider } from '../contexts/SkipLinkContext';
import { CookieConsentProvider } from '../cookiebot';
import { ItemCachedDataProvider } from '../items/ItemCachedProvider';
import { ItemDataProvider } from '../items/ItemDataProvider';
import { buildPrescriptionsPagesQueryKey } from '../queries/prescriptionPages/buildPrescriptionsPagesQueryKey';
import { Provider as ExpeditionCartProvider } from '../templates/prescription/contexts/Cart';
import { AxeDevToolsToggler } from './AxeDevToolsToggler';
import { ConfiguredCartProvider } from './ConfiguredCartProvider';
import { FeatureSwitcher } from './FeatureSwitcher';
import { InContactChat } from './integrations/inContactChat';
import { AnalyticsTracker } from './integrations/tracking/Tracker';

const IS_PRODUCTION = getConfigurationVariable('ENVIRONMENT') === 'prod';

export const ConfiguredEnvironment: React.FC<React.PropsWithChildren> = ({ children }) => {
  const allowedTracingUrls = [
    getConfigurationVariable('DOMAIN'),
    getConfigurationVariable('WEB_API_BASE_URL'),
  ];

  const datadogConfig: DatadogConfig = {
    applicationId: getConfigurationVariable('DATA_DOG_APPLICATION_ID'),
    environment: getConfigurationVariable('ENVIRONMENT'),
    token: getConfigurationVariable('DATA_DOG_CLIENT_TOKEN'),
    sampleRate: IS_PRODUCTION ? 0 : 0,
    serviceName: 'kronans-apotek-web',
    allowedTracingUrls,
    version: getConfigurationVariable('COMMIT_HASH'),
  };

  const testfreaksConfig: TestFreaksConfig = {
    clientId: getConfigurationVariable('TEST_FREAKS_CLIENT_ID'),
    isDemo: getConfigurationVariable('TEST_FREAKS_DEMO') === 'true',
  };

  const featureFlagsConfig: FeatureFlagsConfig = {
    initialFlags: JSON.parse(getConfigurationVariable('FEATURE_FLAGS') || '{}'),
  };

  return (
    <EnvironmentProvider
      datadog={datadogConfig}
      testFreaks={testfreaksConfig}
      featureFlags={featureFlagsConfig}
    >
      {children}
    </EnvironmentProvider>
  );
};

export const queryClient = new QueryClient();

export const AppWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <CookieConsentProvider>
    <QueryClientProvider client={queryClient}>
      <LazyLoadingProvider>
        <RootThemeProvider>
          <ThemeSwitcher>
            <SkipLinkProvider>
              <AxeDevToolsToggler>
                <DeviceProvider>
                  <GrowthBookProvider>
                    <FeatureSwitcher />
                    <AuthProvider
                      onSignOut={() => {
                        queryClient.removeQueries(buildPrescriptionsPagesQueryKey());
                        queryClient.removeQueries(buildMemberQueryKey());
                      }}
                    >
                      <AuthenticatedApiProvider>
                        <ConfiguredEnvironment>
                          <WebsiteMessagesProvider>
                            <ProductRestrictionProvider>
                              <ProductAvailabilityProvider>
                                <ItemDataProvider>
                                  <ItemCachedDataProvider>
                                    <AnalyticsTracker>
                                      <ConfiguredCartProvider>
                                        <ExpeditionCartProvider>
                                          <InContactChat>
                                            <>
                                              <RatingStars />
                                              {children}
                                            </>
                                          </InContactChat>
                                        </ExpeditionCartProvider>
                                      </ConfiguredCartProvider>
                                    </AnalyticsTracker>
                                  </ItemCachedDataProvider>
                                </ItemDataProvider>
                              </ProductAvailabilityProvider>
                            </ProductRestrictionProvider>
                          </WebsiteMessagesProvider>
                        </ConfiguredEnvironment>
                      </AuthenticatedApiProvider>
                    </AuthProvider>
                  </GrowthBookProvider>
                </DeviceProvider>
              </AxeDevToolsToggler>
            </SkipLinkProvider>
          </ThemeSwitcher>
        </RootThemeProvider>
      </LazyLoadingProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </CookieConsentProvider>
);
