import { COLORS, FONT_WEIGHTS, Modal, ModalRef, Preamble } from '@oriola-corporation/ui-components';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface ErrorModalProps {
  title?: string | null;
  subHeading?: string | null;
  description?: React.ReactNode | null;
  errorCode?: string | null;
  closeLabel?: string | null;
  onClose?: () => void;
  hasErrors: boolean;
  id: string;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  title,
  subHeading,
  description,
  errorCode,
  closeLabel,
  onClose,
  hasErrors,
  id,
}) => {
  const { t } = useTranslation('common');

  const ref = useRef<ModalRef>(null);

  useEffect(() => {
    if (hasErrors) {
      ref.current?.openModal();
    }
  }, [hasErrors]);

  return (
    <Modal
      id={id}
      ref={ref}
      title={title || t('errors.modal.title')}
      onClose={onClose}
      actions={[
        {
          onClick: () => ref.current?.closeModal(),
          label: closeLabel || t('errors.modal.closeLabel'),
        },
      ]}
    >
      {subHeading && <Preamble>{subHeading}</Preamble>}
      <div>{description || t('errors.modal.description')}</div>
      {errorCode && (
        <ErrorCodeContainer>
          {t('errors.errorCode')}: {errorCode}
        </ErrorCodeContainer>
      )}
    </Modal>
  );
};

const ErrorCodeContainer = styled.div`
  margin-top: 7px;
  color: ${COLORS.GRAY};
  font-weight: ${FONT_WEIGHTS.SEMI_BOLD};
`;
