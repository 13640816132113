import { UnknownObject } from '@oriola-corporation/kronan-analytics';
import { isServer } from '@packages/gatsby-utils';
import {
  SEARCH_FILTERS_CLEARED,
  SEARCH_FILTERS_MODAL_CLOSED,
  SEARCH_FILTERS_MODAL_OPENED,
  SORT_OPTIONS_DROPDOWN_TOGGLED,
  SORT_OPTIONS_MODAL_CLOSED,
  SORT_OPTIONS_MODAL_OPENED,
  SORT_OPTION_SELECTED,
  TAGS_FILTER_DROPDOWN_TOGGLED,
  TAGS_FILTER_OPTION_TOGGLED,
} from '@packages/search';
import {
  PRESCRIPTIONS_LIST_DROPDOWN_TOGGLED,
  PRESCRIPTIONS_LIST_SORTING_OPTION_CHANGED,
} from '../../../../../../components/expedition/prescriptions/analytics';
import { convertTextToSlug } from '../utils/convertTextToSlug';
import { createSimpleTracker } from '../utils/createSimpleTracker';
import { parseListNameFromMetadata } from '../utils/parseListNameFromMetadata';

interface BaseEvent {
  name: string;
  filterId: string;
  eventName?: string;
}

interface SortEvent extends BaseEvent {
  userInteraction:
    | 'select'
    | 'apply_sorting'
    | 'open_dropdown'
    | 'close_dropdown'
    | 'show_sorting_options'
    | 'close_sorting_options';
  currentSorting: string;
  newSorting?: string;
}

interface FilterEvent extends BaseEvent {
  userInteraction:
    | 'remove'
    | 'add'
    | 'select'
    | 'remove_all_filters'
    | 'show_filters'
    | 'hide_filters'
    | 'apply_filters'
    | 'open_dropdown'
    | 'close_dropdown';
  filterType: string | null;
  filterSelection?: string | null;
  filterNumberOfProducts?: number | null;
}

const valueOrNA = (value: string | number | undefined | null) => value ?? 'N/A';

const createBaseEvent = <T>({
  name,
  filterId,
  eventName,
  eventInfo,
}: BaseEvent & { eventInfo: T }) => ({
  event: name,
  event_info: {
    event_name: eventName ? eventName : name,
    filter_id: valueOrNA(filterId),
    ...eventInfo,
  },
});

const createSortEvent = ({
  name,
  eventName,
  filterId,
  userInteraction,
  currentSorting,
  newSorting,
}: SortEvent) => {
  const eventInfo: UnknownObject = {
    user_interaction: userInteraction,
    current_sorting: currentSorting,
    new_sorting: valueOrNA(newSorting),
  };

  return createBaseEvent({
    name,
    filterId,
    eventName,
    eventInfo,
  });
};

const createFilterEvent = ({
  name,
  filterId,
  eventName,
  userInteraction,
  filterType,
  filterSelection,
  filterNumberOfProducts,
}: FilterEvent) => {
  const eventInfo: UnknownObject = {
    user_interaction: userInteraction,
    filter_type: valueOrNA(filterType),
    filter_selection: valueOrNA(filterSelection),
    filter_number_of_products: valueOrNA(filterNumberOfProducts),
  };

  const isSearchResult = !isServer() && window.location.pathname.includes('hitta-produkter');

  return createBaseEvent({
    name,
    filterId: isSearchResult ? 'search_result' : filterId,
    eventName,
    eventInfo,
  });
};
const parseFilterIdFromMetadata = (metadata: UnknownObject) => {
  return metadata.searchProviderId as string;
};

export const filterProductsTracker = createSimpleTracker({
  [SORT_OPTION_SELECTED]: ({ event: { payload }, metadata }) =>
    createSortEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      eventName: 'sort_products',
      userInteraction: 'select',
      currentSorting: convertTextToSlug(payload.previous.id),
      newSorting: convertTextToSlug(payload.id),
    }),
  [SORT_OPTIONS_DROPDOWN_TOGGLED]: ({ event: { payload }, metadata }) =>
    createSortEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      eventName: 'sort_products',
      currentSorting: convertTextToSlug(payload.id),
      userInteraction: payload.isOpen ? 'open_dropdown' : 'close_dropdown',
    }),
  [SORT_OPTIONS_MODAL_OPENED]: ({ event: { payload }, metadata }) =>
    createSortEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      eventName: 'sort_products',
      currentSorting: convertTextToSlug(payload.id),
      userInteraction: 'show_sorting_options',
    }),
  [SORT_OPTIONS_MODAL_CLOSED]: ({ event: { payload }, metadata }) =>
    createSortEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      eventName: 'sort_products',
      currentSorting: convertTextToSlug(payload.id),
      userInteraction: payload.reason === 'apply' ? 'apply_sorting' : 'close_sorting_options',
    }),
  [SEARCH_FILTERS_CLEARED]: ({ metadata }) =>
    createFilterEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      filterType: null,
      userInteraction: 'remove_all_filters',
    }),
  [SEARCH_FILTERS_MODAL_OPENED]: ({ metadata }) =>
    createFilterEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      filterType: null,
      userInteraction: 'show_filters',
      filterSelection: null,
    }),
  [SEARCH_FILTERS_MODAL_CLOSED]: ({ event: { payload }, metadata }) =>
    createFilterEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      filterType: null,
      userInteraction: payload.reason === 'apply' ? 'apply_filters' : 'hide_filters',
      filterSelection: null,
    }),
  [TAGS_FILTER_OPTION_TOGGLED]: ({ event: { payload }, metadata }) =>
    createFilterEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      filterType: payload.id,
      userInteraction: payload.isSelected ? 'select' : 'remove',
      filterSelection: payload.option.id,
      filterNumberOfProducts: payload.option.count,
    }),
  [TAGS_FILTER_DROPDOWN_TOGGLED]: ({ event: { payload }, metadata }) =>
    createFilterEvent({
      name: 'filter_products',
      filterId: parseFilterIdFromMetadata(metadata),
      filterType: payload.id,
      userInteraction: payload.isOpen ? 'open_dropdown' : 'close_dropdown',
    }),
  [PRESCRIPTIONS_LIST_DROPDOWN_TOGGLED]: ({ event: { payload }, metadata }) =>
    createSortEvent({
      name: 'filter_products',
      eventName: 'sort_products',
      filterId: parseListNameFromMetadata(metadata),
      currentSorting: convertTextToSlug(payload.current),
      userInteraction: payload.isOpen ? 'open_dropdown' : 'close_dropdown',
    }),
  [PRESCRIPTIONS_LIST_SORTING_OPTION_CHANGED]: ({ event: { payload }, metadata }) =>
    createSortEvent({
      name: 'filter_products',
      filterId: parseListNameFromMetadata(metadata),
      userInteraction: 'select',
      eventName: 'sort_products',
      currentSorting: convertTextToSlug(payload.from),
      newSorting: convertTextToSlug(payload.to),
    }),
});
