import axios from 'axios';
import {
  AccessTokenResponse,
  AuthMethod,
  BankIdCollectDTO,
  BankIdInitDTO,
  LoginType,
  MockUser,
} from './types';

export const createApiClient = (basePath: string) => {
  const client = axios.create({
    baseURL: `${basePath}`,
    withCredentials: true,
  });

  return {
    getAxiosInstance: () => client,
    getLoginUrl: (authMethod: AuthMethod, redirectUrl: string) =>
      `${basePath}/auth/login?regId=${authMethod}&redirectUrl=${encodeURIComponent(redirectUrl)}`,
    getFakeLogin: (user: MockUser) =>
      client.get(
        `${basePath}/generate?pnr=${
          user.personalNumber
        }&fname=${user.firstName}&lname=${user.lastName}`
      ),
    getAccessToken: async () => {
      const response = await client.get<AccessTokenResponse>('/auth/refresh');
      return response.data;
    },
    logout: async () => {
      await client.get('/auth/logout');
    },

    initBankId: async (loginType: LoginType) =>
      (await client.post<BankIdInitDTO>(`/v2/bankid/login/${loginType}`)).data,

    collectBankId: async (sessionId: string) => {
      try {
        const response = await client.post<BankIdCollectDTO>(`/v2/bankid/session/${sessionId}`);
        return response.data;
      } catch (error) {
        console.log(error);
        return {
          refreshToken: undefined,
          sessionType: '',
          autoStartToken: undefined,
          sessionId: '',
          QRCode: undefined,
          message: { status: 'failed', hintCode: 'startFailed' },
          code: '',
        };
      }
    },

    logoutBankId: async (sessionId: string) =>
      (await client.post(`/v2/bankid/logout/${sessionId}`)).data,
  };
};
